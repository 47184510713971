/* apply a natural box layout model to all elements */
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  vertical-align: middle;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  line-height: 1.5;
  position: relative;
  color: #212a39;
  background-color: #f4f7f9;
}

p {
  line-height: 2.25;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
}

a {
  text-decoration: none;
}

input:not([type='checkbox']):not([type='radio']) {
  -webkit-appearance: none;
}

select {
  -webkit-appearance: none;
}

.centerFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.centerMargin {
  display: block;
  margin: auto;
}

.animated.fast {
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
}

.animated.medium {
  -webkit-animation-duration: 400ms;
  animation-duration: 400ms;
}
